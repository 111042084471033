import { Injectable } from '@angular/core';
import { CONFIG } from '../services/contentful';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { LanguageService } from './../services/language.service';
import { Observable, switchMap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken,
  });

  constructor(private languageService: LanguageService) {}

  loadNews(): Observable<any> {
    return this.languageService.currentLang$.pipe(
      switchMap((lang: any) =>
        this.cdaClient
          .getEntries({
            content_type: 'news',
            include: 1, // Ensure this is set to include linked entries/assets
            locale: lang
          })
          .then((res: any) => {
            // Link each team member with their photo URL from the included assets
            const items = res.items.map((item: any) => {
              const imageId = item.fields.image?.sys.id;
              const imageUrl = res.includes.Asset.find(
                (asset: any) => asset.sys.id === imageId
              )?.fields.file.url;
              return {
                ...item.fields,
                imageUrl: imageUrl ? 'https:' + imageUrl : null, // Prepend 'https:' to make the URL absolute
              };
            });
            return items;
          })
      )
    );
  }

  richTextToHtml(richText: any): string {
    return documentToHtmlString(richText);
  }
}
