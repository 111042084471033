import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ProjectsService } from '../projects.service';
import { Subscription } from 'rxjs';
import { ProjectDetailsComponent } from '../project-details.component';
import { AddLineBreaksPipe } from '../../pipes/addLineBreaks.pipe';

const CATEGORY_ORDER = [
  'SĂNĂTATE',
  'HEALTH',

  'INFRASTRUCTURĂ',
  'INFRASTRUCTURE',

  'SPORT',
  'SPORTS',

  'ADMINISTRATIV CULTURĂ BIROURI',
  'ADMINISTRATIVE CULTURE OFFICE',

  'EDUCAȚIE',
  'EDUCATION',

  'HOTEL',
  'HOSPITALITY',

  'REZIDENȚIAL',
  'RESIDENTIAL',

  'COMERCIAL',
  'COMMERCIAL',

  'REGENERARE URBANĂ',
  'URBAN RECONSTRUCTION',
];

@Component({
  selector: 'cds-projects-details-place',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    AddLineBreaksPipe,
    ProjectDetailsComponent,
  ],
  templateUrl: './projects-details-place.component.html',
  styleUrl: './projects-details-place.component.scss',
})
export class ProjectsDetailsPlaceComponent implements OnInit {
  selectedCategory: string = 'SANATATE';
  groupedProjects: any = {};
  categories: any = [];
  filteredProjects: any;
  isMobile!: boolean;

  showProjectDetails: boolean = false;
  selectedProjectData: any = null;
  allProjectsShort: any;

  private projectsSubscription!: Subscription;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event?.target.innerWidth > 768) {
      this.isMobile = false;
    }
    if (event?.target.innerWidth < 768) {
      this.isMobile = true;
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProjectsDetailsPlaceComponent>,
    private projectsService: ProjectsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 768 ? true : false;

    this.proccesData();
  }

  proccesData() {
    if (this.data.fields.projects.length === 1) {
      this.showProjectDetails = true;
      this.selectedProjectData = this.data.fields.projects[0];
    }

    this.groupProjectsByCategory(this.data.fields.projects);
    this.allProjectsShort = this.data.fields.projects.map((project: any) => {
      return {
        ...project.fields, // This includes everything inside project.fields
        id: project.sys.id, // This captures the project's system ID
      };
    });
  }

  onNoClick(): void {
    if (this.showProjectDetails === true && this.data.fields.projects.length === 1) {
      this.dialogRef.close();
    } else if (this.showProjectDetails === true) {
      this.showProjectDetails = false;
    } else {
      this.dialogRef.close();
    }
  }

  groupProjectsByCategory(projects: any[]) {
    const groupedProjectsTemp: any = {};

    projects.forEach((project) => {
      const category = project.fields.category;
      if (!groupedProjectsTemp[category]) {
        groupedProjectsTemp[category] = [];
      }
      groupedProjectsTemp[category].push({
        ...project.fields,
        id: project.sys.id,
      });
    });

    this.categories = CATEGORY_ORDER.filter((cat) =>
      // eslint-disable-next-line no-prototype-builtins
      groupedProjectsTemp.hasOwnProperty(cat)
    );

    this.groupedProjects = this.categories.reduce((acc: any, category: any) => {
      acc[category] = groupedProjectsTemp[category];
      return acc;
    }, {});

    this.filteredProjects = this.groupedProjects[this.selectedCategory];
  }

  getProjectById(projectId: string) {
    this.projectsSubscription = this.projectsService
      .getProjectById(projectId)
      .subscribe((res) => {
        this.selectedProjectData = res; // Store the selected project data
        this.showProjectDetails = true; // Show project details
        this.projectsSubscription.unsubscribe();
      });
  }

  // openDialog(selectedProject: any): void {
  //   this.dialogRef.addPanelClass('second-dialog-opened')
  //   const dialogRef = this.dialog.open(ProjectDetailsComponent, {
  //     data: selectedProject,
  //     width: '100vw',
  //     height: '100vh',
  //     maxWidth: '100vw',
  //     maxHeight: '100vh',
  //     panelClass: 'full-screen-modal',
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     this.dialogRef.removePanelClass('second-dialog-opened')
  //   });
  // }

  closeProjectDetails() {
    this.showProjectDetails = false; // Hide project details
  }
}
