import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
  HostListener,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { ProjectsService } from './projects.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ProjectDetailsComponent } from './project-details.component';
import { RouterModule } from '@angular/router';
import { ProjectsDetailsPlaceComponent } from './projects-details-place/projects-details-place.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'cds-projects-place',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    GoogleMap,
    MapMarker,
    MatDialogModule,
    RouterModule,
  ],
  templateUrl: './projects-place.component.html',
  styleUrl: './projects-place.component.scss',
})
export class ProjectsPlaceComponent implements AfterViewInit, OnInit {
  @ViewChild('map') mapComponent!: GoogleMap;

  center: google.maps.LatLngLiteral = { lat: 45.9432, lng: 24.9668 };
  zoom = this.calculateZoomLevel();
  width = this.calculateWidth();
  height = '70vh';
  mapOptions: google.maps.MapOptions = {
    mapTypeId: 'none',
    disableDefaultUI: true,
    draggable: false,
    backgroundColor: 'transparent',
    styles: [
      {
        featureType: 'all',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'all',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };
  markers: google.maps.Marker[] = [];
  private projectsSubscription!: Subscription;

  // Define SVG template for the default red marker
  private readonly redMarkerSVG = `
  <svg width="20" height="20" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="40" fill="#bc8b8b" />
  </svg>`;

  isFiltersOpen = false;
  isMobile!: boolean;
  animationTrigger: boolean = false;

  constructor(
    private projectsService: ProjectsService,
    public dialog: MatDialog,
    private meta: Meta,
    private title: Title
  ) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'CDS se remarcă printr-un portofoliu complex de proiecte esențiale pentru administrația publică și mediul privat, incluzând infrastructură aeroportuară, sănătate, educație și regenerare urbană.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle('Proiecte | Concrete & Design Solutions');
  }

  ngOnInit(): void {
    this.addMetaTags();
  }

  @HostListener('window:resize', ['$event'])
  onResizeW(event: any) {
    if (event?.target.innerWidth > 768) {
      this.isMobile = false;
    }
    if (event?.target.innerWidth < 768) {
      this.isMobile = true;
    }
  }

  ngAfterViewInit() {
    this.isMobile = window.innerWidth < 768 ? true : false;
    const map = this.mapComponent.googleMap!;
    this.setMapZoom();
    this.addCustomOverlay(map);
    this.getProjectLocations();
  }

  @HostListener('window:resize')
  onResize() {
    this.setMapZoom();
  }

  private setMapZoom() {
    const zoomLevel = this.calculateZoomLevel();
    if (this.mapComponent.googleMap) {
      this.mapComponent.googleMap.setZoom(zoomLevel);
    }
  }

  private calculateZoomLevel(): number {
    const width = window.innerWidth;
    if (width < 600) {
      return 5.8;
    } else if (width >= 600 && width < 768) {
      this.height = '60vh';
      return 5.8;
    } else if (width >= 768 && width < 992) {
      return 6;
    } else if (width >= 992 && width < 1200) {
      return 6.7;
    } else if (width >= 1200 && width < 1800) {
      return 7;
    } else {
      return 7.2;
    }
  }

  private calculateWidth(): string {
    const width = window.innerWidth;
    if (width < 768) {
      return '100vw';
    } else if (width >= 768) {
      return '70vw';
    } else {
      return '70vw';
    }
  }

  addCustomOverlay(map: google.maps.Map) {
    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(43.242, 19.891),
      new google.maps.LatLng(48.635, 30.129)
    );

    const overlay = new google.maps.GroundOverlay(
      '../../assets/projects/harta-romania.png',
      bounds
    );
    overlay.setMap(map);
  }

  getProjectLocations() {
    this.projectsService.getProjectLocations().then((res: any) => {
      this.addMarkersToMap(res);
    });
  }

  addMarkersToMap(locations: any[]) {
    const map = this.mapComponent.googleMap!;

    // Clear any existing markers
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];

    locations.forEach((location) => {
      const { lat, lon } = location.fields.coordinates;
      const markerSize = this.getResponsiveMarkerSize(); // Dynamically get marker size

      const marker = new google.maps.Marker({
        position: { lat, lng: lon },
        map: map,
        icon: {
          url:
            'data:image/svg+xml;charset=UTF-8;base64,' +
            btoa(this.redMarkerSVG),
          scaledSize: new google.maps.Size(markerSize, markerSize), // Use dynamic size
        },
      });

      // Add mouseout listener to change marker icon back to red
      marker.addListener('mouseout', () => {
        marker.setIcon({
          url:
            'data:image/svg+xml;charset=UTF-8;base64,' +
            btoa(this.redMarkerSVG),
          scaledSize: new google.maps.Size(markerSize, markerSize), // Use dynamic size
        });
      });

      // Click listener to perform actions when a marker is clicked
      marker.addListener('click', () => {
        this.openDialog(location);
      });

      this.markers.push(marker);
    });
  }

  getResponsiveMarkerSize(): number {
    // Check if device width is less than 768px, typical breakpoint for mobile devices
    return window.innerWidth < 768 ? 12 : 20; // Return 8px for mobile, otherwise 20px
  }

  openDialog(selectedProject: any): void {
    const dialogRef = this.dialog.open(ProjectsDetailsPlaceComponent, {
      data: selectedProject,
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'full-screen-modal',
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  getProjectById(projectId: string) {
    this.projectsSubscription = this.projectsService
      .getProjectById(projectId)
      .subscribe((res) => {
        this.openDialog(res);
        this.projectsSubscription.unsubscribe();
      });
  }

  toggleFilters() {
    this.animationTrigger = !this.animationTrigger;
    if (this.isFiltersOpen === false) {
      setTimeout(() => {
        this.isFiltersOpen = !this.isFiltersOpen;
      }, 200);
    }
  }
}
