<div class="secondary-page">
  <div class="animate__animated animate__fadeIn secondary-page-main-container">
    <div class="secondary-page-title-container">
      <h1 class="secondary-page-title animate__animated animate__fadeInRight">{{ 'careers.title' | translate }}</h1>
    </div>

    <cds-scroll direction="up" [scrollContainer]="scrollContainer"></cds-scroll>
    <div class="careers-container secondary-page-content-container scrollable-container" #scrollContainer>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let item of careersItems" [hideToggle]="isMobile" (opened)="panelOpenState = true"
          (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="title-mobile">
              {{ item.title }}
              <mat-icon aria-hidden="false" fontIcon="expand_more" [ngClass]="{'rotate-icon': panelOpenState}"
                *ngIf="isMobile"></mat-icon>
            </mat-panel-title>
            <mat-panel-description>
              <div [innerHTML]="getRichText(item.shortDescription)"></div>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div style="width: 100%; max-width: 100%;" [innerHTML]="getRichText(item.description)"></div>
          <button class="apply-button" (click)="openDialog(item)">{{ 'careers.apply' | translate}}</button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <cds-scroll direction="down" [scrollContainer]="scrollContainer"></cds-scroll>
  </div>
</div>
