import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'cds-scroll',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './scroll.component.html',
  styleUrl: './scroll.component.scss',
})
export class ScrollComponent implements OnInit, AfterViewInit {
  @Input() direction!: 'up' | 'down';
  @Input() scrollContainer!: Element;

  @ViewChild('scrollButton', { static: false })
  scrollButton!: ElementRef<HTMLImageElement>;

  showScrollButton: boolean = false;

  isMobile!: boolean;
  isProjects!: boolean;

  constructor(private renderer: Renderer2, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isProjects = event.url === '/projects/type';
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    if (event?.target.innerWidth > 768) {
      this.isMobile = false;
      this.updateButtonPosition();
    }
    if (event?.target.innerWidth < 768) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 768 ? true : false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateButtonPosition();
      this.checkForScrollableContent();
    }, 100);
  }
  scrollTo() {
    const scrollAmount = 200;
    const directionMultiplier = this.direction === 'up' ? -1 : 1;
    this.scrollContainer.scrollBy(0, directionMultiplier * scrollAmount);
  }

  updateButtonPosition() {
    if (this.scrollContainer && this.scrollButton) {
      const containerWidth =
        this.scrollContainer.clientWidth === 0
          ? window.innerWidth
          : this.scrollContainer.clientWidth;
      const buttonWidth = this.scrollButton.nativeElement.offsetWidth;
      if (this.router.url === '/projects/type' || this.isProjects) {
        this.renderer.setStyle(
          this.scrollButton.nativeElement,
          'left',
          `50vw`
        );
      } else {
        const buttonX = (containerWidth - buttonWidth) / 2 + 320;
        this.renderer.setStyle(
          this.scrollButton.nativeElement,
          'left',
          `${buttonX}px`
        );
      }


    }
  }
  checkForScrollableContent() {
    const hasVerticalScroll = this.scrollContainer.scrollHeight > this.scrollContainer.clientHeight;
    this.showScrollButton = hasVerticalScroll;
    if (!this.isMobile && !hasVerticalScroll) {
      this.renderer.setStyle(
        this.scrollButton.nativeElement,
        'display',
        'none'
      );
    } else {
      this.renderer.setStyle(
        this.scrollButton.nativeElement,
        'display',
        'block'
      );
    }
  }
}
