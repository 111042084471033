import { LanguageService } from './../services/language.service';
import { ProjectsService } from './projects.service';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ProjectDetailsComponent } from './project-details.component';
import { IntroService } from '../services/intro.service';
import { Subscription } from 'rxjs';
import { AddLineBreaksPipe } from '../pipes/addLineBreaks.pipe';
import { TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SidenavService } from '../sidenav/sidenav.service';
import { Meta, Title } from '@angular/platform-browser';

const CATEGORY_ORDER = [
  'SĂNĂTATE',
  'HEALTH',

  'INFRASTRUCTURĂ',
  'INFRASTRUCTURE',

  'SPORT',
  'SPORTS',

  'ADMINISTRATIV CULTURĂ BIROURI',
  'ADMINISTRATIVE CULTURE OFFICE',

  'EDUCAȚIE',
  'EDUCATION',

  'HOTEL',
  'HOSPITALITY',

  'REZIDENȚIAL',
  'RESIDENTIAL',

  'COMERCIAL',
  'COMMERCIAL',

  'REGENERARE URBANĂ',
  'URBAN RECONSTRUCTION',
];

@Component({
  selector: 'cds-projects',
  standalone: true,
  imports: [SharedModule, MatDialogModule, AddLineBreaksPipe, RouterModule],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
})
export class ProjectsComponent implements OnInit, AfterViewInit, OnDestroy {
  isFiltersOpen = false;
  isMobile!: boolean;
  animationTrigger: boolean = false;
  introStarted = false;
  isIconAppeared = false;

  selectedCategory: string = 'SANATATE';

  groupedProjects: any = {};
  categories: any = [];
  filteredProjects: any;
  allProjectsShort: any;
  private projectsSubscription!: Subscription;

  filters: any = {};

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
    if (event?.target.innerWidth > 768) {
      this.isMobile = false;
    }
    if (event?.target.innerWidth < 768) {
      this.isMobile = true;
    }
  }

  introPlayed: boolean = false;
  private sidenavSubscription!: Subscription;
  private filtersSubscription!: Subscription;
  isSidenavOpen: boolean = false;

  constructor(
    private projectsService: ProjectsService,
    public dialog: MatDialog,
    private introService: IntroService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private sidenavService: SidenavService,
    private meta: Meta,
    private title: Title
  ) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'CDS se remarcă printr-un portofoliu complex de proiecte esențiale pentru administrația publică și mediul privat, incluzând infrastructură aeroportuară, sănătate, educație și regenerare urbană.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle('Proiecte | Concrete & Design Solutions');
  }

  ngOnInit(): void {
    this.addMetaTags();
    this.isMobile = window.innerWidth < 768 ? true : false;
    this.getAllProjectsShort();
    this.introPlayed = this.introService.hasIntroPlayed;
    this.introStarted = this.introPlayed === true ? true : false;
    this.sidenavSubscription = this.sidenavService
      .getStateSidenav()
      .subscribe((isOpen) => {
        this.isSidenavOpen = isOpen;
      });
    this.filtersSubscription = this.sidenavService
      .getStateFilters()
      .subscribe((isOpen) => {
        if (isOpen) {
          this.animationTrigger = true;
        } else {
          this.animationTrigger = false;
        }

        this.isFiltersOpen = isOpen;
      });
  }

  ngAfterViewInit() {
    this.checkWindowSize();
  }

  ngOnDestroy(): void {
    if (this.filteredProjects) {
      this.filtersSubscription.unsubscribe();
    }

    if (this.sidenavSubscription) {
      this.sidenavSubscription.unsubscribe();
    }
  }

  getAllProjectsShort() {
    this.projectsSubscription = this.projectsService
      .getAllProjectsShort()
      .subscribe((res) => {
        this.allProjectsShort = res.map((project: any) => {
          return {
            ...project.fields, // This includes everything inside project.fields
            id: project.sys.id, // This captures the project's system ID
          };
        });
        this.groupProjectsByCategory(res);
      });
  }

  getProjectById(projectId: string) {
    this.projectsSubscription = this.projectsService
      .getProjectById(projectId)
      .subscribe((res) => {
        this.openDialog(res);
        this.projectsSubscription.unsubscribe();
      });
  }

  getAllProjectsShortByCategory(category: string) {
    this.projectsService.getAllProjectsShortByCategory(category).then((res) => {
      this.groupedProjects = res;
    });
  }

  groupProjectsByCategory(projects: any[]) {
    const groupedProjectsTemp: any = {};

    projects.forEach((project) => {
      const category = project.fields.category;
      if (!groupedProjectsTemp[category]) {
        groupedProjectsTemp[category] = [];
      }
      groupedProjectsTemp[category].push({
        ...project.fields,
        id: project.sys.id,
      });
    });

    this.categories = CATEGORY_ORDER.filter((cat) =>
      // eslint-disable-next-line no-prototype-builtins
      groupedProjectsTemp.hasOwnProperty(cat)
    );

    this.groupedProjects = this.categories.reduce((acc: any, category: any) => {
      acc[category] = groupedProjectsTemp[category];
      return acc;
    }, {});

    this.filteredProjects = this.groupedProjects[this.selectedCategory];
  }

  private checkWindowSize() {
    this.animationTrigger = false;
    this.isFiltersOpen = false;
  }

  toggleFilters() {
    if (this.isSidenavOpen) {
      this.sidenavService.toggleSidenav();
    }

    this.animationTrigger = !this.animationTrigger;
    if (this.isFiltersOpen === false) {
      this.sidenavService.toggleFilters();
      // setTimeout(() => {
      //   this.isFiltersOpen = !this.isFiltersOpen;
      // }, 200);
    }
  }

  filter(category: string) {
    this.selectedCategory = category;
    this.allProjectsShort = this.groupedProjects[category];
  }

  openDialog(selectedProject: any): void {
    const dialogRef = this.dialog.open(ProjectDetailsComponent, {
      data: selectedProject,
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'full-screen-modal',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });
  }

  introEnded(event?: any) {
    setTimeout(() => {
      this.introService.markIntroAsPlayed();
    }, 1000);
  }

  playing() {
    this.introStarted = true;

    setTimeout(() => {
      this.isIconAppeared = true;
    }, 2000);

    setTimeout(() => {
      this.introEnded();
    }, 3200);
    setTimeout(() => {
      this.introPlayed = true;
    }, 5000);
  }

  changeLangFilters(category: string) {
    this.translate
      .get(`projects.category.${category}`)
      .subscribe((translation: string) => {
        this.filter(translation);
      });
  }
}
