import { Injectable } from '@angular/core';
import { CONFIG } from '../services/contentful';
import { createClient } from 'contentful';

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken,
  });

  constructor() {}

  getTeam(): Promise<any> {
    return this.cdaClient
      .getEntries({
        content_type: 'team',
        include: 1, // Ensure this is set to include linked entries/assets
      })
      .then((res: any) => {
        // Link each team member with their photo URL from the included assets
        const items = res.items.map((item: any) => {
          const imageId = item.fields.image?.sys.id;
          const imageUrl = res.includes.Asset.find(
            (asset: any) => asset.sys.id === imageId
          )?.fields.file.url;
          return {
            ...item.fields,
            imageUrl: imageUrl ? 'https:' + imageUrl : null, // Prepend 'https:' to make the URL absolute
          };
        });
        return items;
      });
  }
}
