import { IntroService } from './../services/intro.service';
import { Component, HostListener, OnInit } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'cds-home',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  introPlayed: boolean = false;
  public audio!: HTMLAudioElement;
  screenWidth: number = window.innerWidth;
  introStarted = false;

  constructor(
    private introService: IntroService,
    private meta: Meta,
    private title: Title
  ) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Organizația noastră este renumită pentru serviciile sale de proiectare în construcții complete și integrate, susținute de o echipă multidisciplinară de peste 100 de specialiști.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle('CDS | Proiectare în construcții');
  }

  ngOnInit() {
    this.addMetaTags();
    this.introPlayed = this.introService.hasIntroPlayed;
    this.introStarted = this.introPlayed === true ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  playIntroAudio() {
    const audio = new Audio();
    audio.src = '../assets/intro music.mp3';
    audio.load();
    audio.volume = 0.02;
    audio.play();
  }

  introEnded(event?: any) {
    setTimeout(() => {
      this.introService.markIntroAsPlayed();
    }, 500);
  }

  playing() {
    this.introStarted = true;
    setTimeout(() => {
      this.introEnded();
      this.introPlayed = true;
    }, 10000);
  }
}
