<video autoplay [playsInline]="true" [muted]="true" loop oncanplay="this.play()" onloadedmetadata="this.muted = true"
  id="myVideo" class="animate__animated animate__fadeIn animate__slower" style="z-index: 0;"
  [ngClass]="{'delay-intro': !introPlayed}">
  <source src="../../assets/Cracks Background.mp4" type="video/mp4">
</video>
<!-- <video autoplay [playsInline]="true" [muted]="true" loop oncanplay="this.play()" *ngIf="isProjects"
  onloadedmetadata="this.muted = true" id="myVideo"
  class="mobile-background animate__animated animate__fadeIn animate__slower" style="z-index: 0;"
  [ngClass]="{'delay-intro': !introPlayed}">
  <source src="../assets/Showreel CDS_background.mp4" type="video/mp4">
</video> -->

<div class="main-container">
  <cds-sidenav style="z-index: 1000;" (lang)="setLang($event)"></cds-sidenav>
  <router-outlet style="z-index: 1;"></router-outlet>
</div>
