import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IntroService {
  private introPlayedKey = 'introPlayed';
  introFinished = new EventEmitter<boolean>();

  constructor() {}

  markIntroAsPlayed() {
    sessionStorage.setItem(this.introPlayedKey, 'true');
    this.introFinished.emit(true);
  }

  get hasIntroPlayed(): boolean {
    return sessionStorage.getItem(this.introPlayedKey) === 'true';
  }
}
