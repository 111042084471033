import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ProjectsService } from './projects.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'cds-project-details',
  standalone: true,
  imports: [SharedModule, MatDialogModule],
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.scss',
})
export class ProjectDetailsComponent implements OnInit {
  photos: string[] = [];
  currentPhotoIndex = 0;
  isHandset: boolean = false;
  descriptionExpanded = false; // Add this line

  @Input() embeddedData: any;
  @Input() isDetails = false;
  @Output() close = new EventEmitter<void>();
  data!: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    @Optional() public dialogRef: MatDialogRef<ProjectDetailsComponent>,
    private projectsService: ProjectsService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .subscribe((result) => (this.isHandset = result.matches));

    if (this.embeddedData) {
      this.data = this.embeddedData;
      this.initializeComponentData();
    }
    else if (this.dialogData) {
      this.data = this.dialogData;
      this.initializeComponentData();
    } else {
      this.data = this.dialogData;
      this.initializeComponentData();
    }
  }

  initializeComponentData() {
    this.data.fields.descriptionHtml = this.getRichText(
      this.data.fields.description
    );
    this.photos = this.data.fields?.photos?.map((res: any) => {
      return res.fields.file.url;
    });
  }

  onNoClick(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      this.close.emit(); // Use this to notify the parent component when embedded
    }
  }

  getRichText(richText: any) {
    if (!richText) {
      return ''; // Return an empty string if the rich text content is not yet available
    }
    return this.projectsService.richTextToHtml(richText);
  }

  @HostListener('window:wheel', ['$event'])
  onWindowScroll(event: WheelEvent) {
    if (this.isHandset) {
      // Disable photo change functionality on mobile
      return;
    }
    if (event.deltaY > 0) {
      this.nextPhoto();
    } else {
      this.previousPhoto();
    }
  }

  nextPhoto() {
    if (this.currentPhotoIndex < this.photos.length - 1) {
      this.currentPhotoIndex++;
    }
  }

  previousPhoto() {
    if (this.currentPhotoIndex > 0) {
      this.currentPhotoIndex--;
    }
  }

  toggleDescription() {
    this.descriptionExpanded = !this.descriptionExpanded;
  }
}
