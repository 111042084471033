import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'cds-parteners',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './parteners.component.html',
  styleUrl: './parteners.component.scss',
})
export class PartenersComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'La CDS, oferim servicii de proiectare în domeniul construcțiilor, colaborând cu parteneri de top pentru a asigura excelența și inovația în fiecare proiect.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle(
      'Parteneri | CDS - Alianțe strategice în industria construcțiilor'
    );
  }

  ngOnInit(): void {
    this.addMetaTags();
  }
}
