import { SidenavService } from './sidenav.service';
import { LanguageService } from './../services/language.service';
import {
  Component,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { IntroService } from '../services/intro.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cds-sidenav',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {
  isHomePage: boolean = false;
  isProjects: boolean = false;
  introPlayed: boolean = false;
  isSidenavOpen: boolean = false;
  isFiltersOpen: boolean = false;
  animationTrigger: boolean = false;
  isCompanyClicked = false;

  public audio!: HTMLAudioElement;
  private isPlaying: boolean = false;

  selectedPage = false;

  introEndSubscription!: Subscription;
  introEnded = false;
  animationPlayed = false;
  private sidenavSubscription!: Subscription;
  private filtersSubscription!: Subscription;

  @ViewChild('flagImage') flagElement!: ElementRef;
  flag: string = '../../assets/united-kingdom.png';
  @Output() lang: EventEmitter<string> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 768 && this.router.url === '/home') {
      this.router.navigate(['/projects']);
    }
    this.checkWindowSize();
  }

  constructor(
    public router: Router,
    private introService: IntroService,
    private languageService: LanguageService,
    private sidenavService: SidenavService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is the home page
        this.isHomePage = event.url === '/home' || event.url === '/';
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is the home page
        this.isProjects =
          event.url === '/projects/type' || event.url === '/projects/place';
      }
    });
  }

  ngOnInit(): void {
    this.getIntroEnded();
    this.sidenavSubscription = this.sidenavService
      .getStateSidenav()
      .subscribe((isOpen) => {
        if (isOpen) {
          this.animationTrigger = true;
        } else {
          this.animationTrigger = false;
        }
        this.isSidenavOpen = isOpen;
      });

    this.filtersSubscription = this.sidenavService
      .getStateFilters()
      .subscribe((isOpen) => {
        this.isFiltersOpen = isOpen;
      });
  }

  ngAfterViewInit() {
    // Ensure that the initial window size is checked when the component is initialized.
    this.checkWindowSize();
  }

  ngOnDestroy(): void {
    if (this.sidenavSubscription) {
      this.sidenavSubscription.unsubscribe();
    }

    if (this.filtersSubscription) {
      this.filtersSubscription.unsubscribe();
    }
  }

  getIntroEnded() {
    this.introPlayed = this.introService.hasIntroPlayed;
    if (this.introPlayed) {
      this.animationPlayed = true;
    } else {
      this.introEndSubscription = this.introService.introFinished.subscribe(
        (res) => {
          this.introPlayed = res;
          if (res === true) {
            setTimeout(() => {
              this.animationPlayed = res;
            }, 6000);
          }
        }
      );
    }
  }

  private checkWindowSize() {
    this.animationTrigger = false;
    this.isSidenavOpen = false;
  }

  toggleSidenav() {
    if (this.isFiltersOpen) {
      this.sidenavService.toggleFilters();
    }

    this.animationTrigger = !this.animationTrigger;
    if (this.isSidenavOpen === false) {
      this.sidenavService.toggleSidenav();
    }
  }

  audioClick() {
    this.audio = new Audio();
    this.audio.src = '../../assets/click sound.mp3';
    this.audio.volume = 0.5;
    this.audio.load();
    this.audio.play();

    this.audio.onended = () => {
      this.isPlaying = false;
    };
  }

  playClick() {
    if (!this.isPlaying) {
      this.audioClick();
    }
    this.toggleSidenav();
  }

  navigateTo(route: string) {
    if (route === 'projects/type') {
      this.isProjects = true;
    }
    this.router.navigate([route]);
    this.selectedPage = true;
  }

  changeLang() {
    const flagElement = this.flagElement.nativeElement;

    flagElement.classList.add('animate__animated', 'animate__fadeOut');

    this.flag =
      this.flag === '../../assets/united-kingdom.png'
        ? '../../assets/romania.png'
        : '../../assets/united-kingdom.png';
    const lang =
      this.flag === '../../assets/united-kingdom.png' ? 'ro' : 'en-US';
    this.languageService.changeLanguage(lang); // Use LanguageService to change the language
    this.lang.emit(lang);

    flagElement.classList.remove('animate__fadeOut');
    flagElement.classList.add('animate__fadeIn');

    setTimeout(() => {
      flagElement.classList.remove('animate__fadeIn');
    }, 1000);
  }
}
