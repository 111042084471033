<div *ngIf="!introPlayed">
  <video autoplay [playsInline]="true" (ended)="introEnded($event)" (play)="playing()" [muted]="true" id="myVideo" style="z-index: 0;"
    class="intro-video animate__animated animate__fadeOut">
    <source src="../../assets/intro.mp4" type="video/mp4">
  </video>
</div>

<video autoplay [playsInline]="true" [muted]="true" loop id="myVideo" class="animate__animated animate__fadeIn"
  [ngClass]="{'bg-video': !introPlayed && introStarted}" style="z-index: 0;">
  <source src="../../assets/Showreel CDS_background.mp4" type="video/mp4">
</video>

<div *ngIf="introPlayed">
  <div class="description-container animate__animated animate__fadeIn animate__slower animate__delay-1s">
    <div class="description">{{'home.title' | translate}}</div>
    <div class="smaller-description">{{'home.subtitle' | translate}}</div>
  </div>

  <div class="footer-row">
    <div class="left animate__animated animate__fadeInLeft animate__slower">
      <span class="adress">Str. Grigore Manolescu Nr. 7A Corp C, Sector 1 Bucuresti RO</span>
      <br *ngIf="screenWidth < 992;">
      <span class="phone"> ― 004 037 148 5404</span>
    </div>
    <div class="right animate__animated animate__fadeInRight animate__slower">
      <span class="company">© CONCRETE & DESIGN SOLUTIONS S.R.L. </span>
      <span class="rights"> All rights reserved.</span>
    </div>
  </div>
</div>
