<div class="secondary-page">
  <div class="animate__animated animate__fadeIn secondary-page-main-container">
    <div class="secondary-page-title-container">
      <h1 class="secondary-page-title animate__animated animate__fadeInRight">{{ 'people.title' | translate }}</h1>
    </div>

    <cds-scroll direction="up" [scrollContainer]="scrollContainer"></cds-scroll>

    <div class="people-container secondary-page-content-container scrollable-container" #scrollContainer>
      <div class="item-container" *ngFor="let employee of people">
        <img class="image" [src]="employee.imageUrl" alt="Photo of {{ employee.name }}">
        <div class="name-role">
          <span class="name">{{employee?.name}}</span>
          <span class="role">{{employee?.position}}</span>
        </div>
      </div>
    </div>
  </div>

  <cds-scroll direction="down" [scrollContainer]="scrollContainer"></cds-scroll>

</div>
