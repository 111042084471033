import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AboutService } from './about.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'cds-about',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
})
export class AboutComponent implements OnInit {
  about: any;
  imageUrls: any;

  constructor(
    public aboutService: AboutService,
    private meta: Meta,
    private title: Title
  ) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Viziunea de dezvoltare a companiei Concrete & Design Solutions se bazează pe capacitatea de asumare a unor soluții de proiectare care integrează atât provocările tehnice, cât și o viziune creativă, cu soluții arhitectrale îndrăznețe, transformatoare pentru întreaga zonă de implementare.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle(
      'Despre noi | CDS - Inovație în proiectare în construcții'
    );
  }

  ngOnInit(): void {
    this.addMetaTags();
    this.getAbout();
  }

  getAbout() {
    this.aboutService.getAbout().subscribe((res: any) => {
      this.about = res.items[0]; // Assuming that the about data is the first item
      this.imageUrls = this.aboutService.processEntryForImages(
        this.about,
        res.includes
      );

      // Now you can safely call getRichText() since 'about' has been populated
      this.about.fields.sectionContentHtml = this.getRichText(
        this.about.fields.sectionContent
      );
      this.about.fields.sectionContentVisionHtml = this.getRichText(
        this.about.fields.sectionContentVision
      );
      this.about.fields.sectionContentMissionHtml = this.getRichText(
        this.about.fields.sectionContentMission
      );
    });
  }

  getRichText(richText: any) {
    if (!richText) {
      return ''; // Return an empty string if the rich text content is not yet available
    }
    return this.aboutService.richTextToHtml(richText);
  }
}
