import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'cds-beneficiaries',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './beneficiaries.component.html',
  styleUrl: './beneficiaries.component.scss',
})
export class BeneficiariesComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Concrete & Design Solutions (CDS) colaborează cu instituții publice și companii de prestigiu pentru a oferi soluții inovatoare, combinând expertiza tehnică cu o viziune creativă.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle(
      'Beneficiari | CDS - Parteneriate de succes în proiectare'
    );
  }

  ngOnInit(): void {
    this.addMetaTags();
  }
}
