import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare global {
  interface Window { gtag: Function; }
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  sendEvent(eventName: string, params: {}) {
    if (isPlatformBrowser(this.platformId)) {
      window['gtag']('event', eventName, params);
    }
  }

  sendPageView(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      window['gtag']('config', 'G-J75DDW8JHT', {
        page_path: url,
      });
    }
  }
}
