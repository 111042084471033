import { IntroService } from './../src/app/services/intro.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class IntroGuard implements CanActivate {
  constructor(
    private introService: IntroService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (!this.introService.hasIntroPlayed && window.innerWidth > 768) {
      this.router.navigate(['/home']);
      return false;
    } else if (!this.introService.hasIntroPlayed && window.innerWidth < 768) {
      this.router.navigate(['projects/type']);
      return false;
    }
     else {
      sessionStorage.setItem('introPlayed', 'true')
    }
    return true;
  }
}
