import { Component, HostListener, OnInit } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

declare let google: any;

declare global {
  interface Window {
    initMap: () => void;
  }
}

@Component({
  selector: 'cds-contact',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent implements OnInit {
  screenWidth: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  constructor(private meta: Meta, private title: Title) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Ne găsiți pe Str. Grigore Manolescu Nr. 7A, Corp C, Parter, 011233 București, Sector 1. Telefon: +40 371 385 404.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle('Contact | Concrete & Design Solutions');
  }

  ngOnInit(): void {
    this.addMetaTags();
    this.loadGoogleMapsScript();
  }

  loadGoogleMapsScript() {
    window.initMap = this.initMap.bind(this);

    const script = document.createElement('script');
    script.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCElWtAjRb4Vt9cI47BUGqK_4m1Eaw6qXY&callback=initMap&libraries=places';

    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  initMap(): void {
    const mapOptions = {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 15,
      mapTypeControl: false,
      fullscreenControl: false,
      styles: [
        {
          featureType: 'all',
          stylers: [{ saturation: -100 }, { gamma: 0.5 }],
        },
      ],
    };

    const map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      mapOptions
    );

    const service = new google.maps.places.PlacesService(map);

    service.findPlaceFromQuery(
      {
        query: 'Concrete & Design Solutions S.R.L.',
        fields: ['name', 'geometry', 'formatted_address', 'place_id'],
      },
      (results: any, status: any) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          results &&
          results[0]
        ) {
          map.setCenter(results[0].geometry.location);
          map.setZoom(17);

          const marker = new google.maps.Marker({
            map: map,
            position: results[0].geometry.location,
          });

          // Create an InfoWindow
          const infoWindow = new google.maps.InfoWindow();

          // Use the place ID to get detailed information
          service.getDetails(
            {
              placeId: results[0].place_id,
              fields: [
                'name',
                'formatted_address',
                'formatted_phone_number',
                'website',
              ],
            },
            (place: any, status: any) => {
              if (
                status === google.maps.places.PlacesServiceStatus.OK &&
                place
              ) {
                const contentString = `
              <div><strong>${place.name}</strong><br>
              Address: ${place.formatted_address}<br>
              Phone: ${place.formatted_phone_number}<br>
              Website: <a href="${place.website}" target="_blank">${place.website}</a>
              </div>
            `;
                infoWindow.setContent(contentString);

                // Open the InfoWindow immediately
                infoWindow.open({
                  anchor: marker,
                  map,
                  shouldFocus: false,
                });
              }
            }
          );

          marker.addListener('click', () => {
            infoWindow.open({
              anchor: marker,
              map,
              shouldFocus: false,
            });
          });
        }
      }
    );
  }
}
