import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PeopleService } from './people.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'cds-people',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './people.component.html',
  styleUrl: './people.component.scss',
})
export class PeopleComponent implements OnInit {
  people: any;

  constructor(
    private peopleService: PeopleService,
    private meta: Meta,
    private title: Title
  ) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Cu o echipă formată din manageri de proiect, arhitecți și ingineri specializați în proiectare structurală și de instalații, Concrete & Design Solutions oferă o gamă largă și complexă de soluții.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle('Echipa | Concrete & Design Solutions');
  }

  ngOnInit(): void {
    this.addMetaTags();
    this.getTeam();
  }

  getTeam() {
    this.peopleService.getTeam().then((res) => {
      this.people = res;
    });
  }
}
