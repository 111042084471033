<div class="container">
  <button class="exit-button" (click)="onNoClick()">{{'projects.exit' | translate}}</button>

  <form (ngSubmit)="onSubmit()" #emailForm="ngForm" class="form-container">
    <div class="form-group">
      <label for="email">{{ 'careers.email' | translate}}</label>
      <div class="input-container">
        <input type="email" id="email" required [(ngModel)]="email" name="email" #emailField="ngModel"
          class="form-input">
        <!-- <button *ngIf="email" type="remove" class="clear-button" aria-label="Clear" (click)="email=''">X</button> -->
      </div>
      <div *ngIf="emailField.invalid && (emailField.dirty || emailField.touched)" class="error-message">
        <div *ngIf="emailField.errors?.['required']">{{ 'careers.required-email' | translate}}</div>
        <div *ngIf="emailField.errors?.['email']">{{ 'careers.invalid-email' | translate}}</div>
      </div>
    </div>

    <div class="form-group">
      <label for="message">Message</label>
      <div class="input-container">
        <textarea id="message" required [(ngModel)]="message" name="message" #messageField="ngModel"
          class="form-input"></textarea>
        <!-- <button *ngIf="message" type="remove" class="clear-button" aria-label="Clear" (click)="message=''">X</button> -->
      </div>
      <div *ngIf="messageField.invalid && (messageField.dirty || messageField.touched)" class="error-message">
        <div *ngIf="messageField.errors?.['required']">{{ 'careers.required-message' | translate}}</div>
      </div>
    </div>

    <div class="upload-container form-container">
      <!-- <label for="attachment">Attachment:</label> -->
      <input type="file" id="attachment" (change)="onFileChange($event)" hidden #fileInput>
      <button type="button" (click)="fileInput.click()">{{ 'careers.choose-file' | translate}}</button>
      <span *ngIf="attachment">{{ attachment.name }}
        <button type="remove" (click)="removeAttachment(fileInput)" class="remove-button">
          x
          <!-- <mat-icon aria-hidden="false" fontIcon="close" style="font-size: 8px;"></mat-icon> -->
        </button>
      </span>
      <span *ngIf="!attachment">{{ 'careers.disclaimer' | translate }}</span>
    </div>
    <div *ngIf="fileError" class="error-message">{{ fileError }}</div>

    <button class="send-email" type="submit" [ngClass]="{'disabled-button': emailForm.invalid || isSubmitting}"
      [disabled]="emailForm.invalid || isSubmitting" style="margin-top: 12px;">{{
      'careers.send-email' | translate}}</button>
  </form>

</div>
