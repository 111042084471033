<div *ngIf="isMobile">
  <video autoplay [playsInline]="true" [muted]="true" loop id="myVideo" class="animate__animated animate__fadeIn"  style="z-index: 0;">
    <source src="../../assets/Showreel CDS_background.mp4" type="video/mp4">
  </video>
</div>

<div class="filter-button animate__animated animate__fadeIn animate__slower" (click)="toggleFilters()" *ngIf="isMobile">
  <img src="../../assets/projects/filters-w.png" width="24" *ngIf="!animationTrigger">
  <img src="../../assets/projects/filters-b.png" width="24" *ngIf="animationTrigger">
</div>
<div class="filters-container animate__animated"
  [ngClass]="{'fadeInRightAnimation': animationTrigger, 'fadeOutrightAnimation': !animationTrigger}"
  *ngIf="isFiltersOpen">
  <div class="category-title" style="padding-left: 1em;" [routerLink]="['/projects/type']">{{'sidenav.projects-type' | translate}}</div>
  <div class="category-title" style="padding-left: 1em;" [routerLink]="['/projects/place']" routerLinkActive="router-link-active" >{{'sidenav.projects-place' | translate}}</div>
</div>
<div class="secondary-page">
  <div class="animate__animated animate__fadeIn secondary-page-main-container">
    <div class="secondary-page-title-container">
      <h1 class="secondary-page-title animate__animated animate__fadeInRight">
        {{ 'projects.title' | translate }}
      </h1>
    </div>
  </div>

  <div class="content-container secondary-page-content-container scrollable-container" #scrollContainer>
    <google-map #map [height]="height" [width]="width" class="google-map-container" [center]="center" [zoom]="zoom" [options]="mapOptions"></google-map>
  </div>
</div>
