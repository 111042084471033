<div class="secondary-page">
  <div class="animate__animated animate__fadeIn secondary-page-main-container">
    <div class="secondary-page-title-container">
      <h1 class="secondary-page-title animate__animated animate__fadeInRight">{{'about.title' | translate}}</h1>
    </div>

    <cds-scroll direction="up" [scrollContainer]="scrollContainer"></cds-scroll>

    <div class="content-container secondary-page-content-container scrollable-container" #scrollContainer>

      <!-- <div class="row">
        <span class="left" [innerHTML]="about?.fields?.sectionContentHtml"></span>
        <div class="right images">
          <img *ngIf="imageUrls?.[0]" [src]="imageUrls[0] | secureUrl" alt="" class="offset-image two-images">
          <img *ngIf="imageUrls?.[1]" [src]="imageUrls[1] | secureUrl" alt="" class="two-images">
        </div>
      </div>

      <div class="row">
        <div class="left picture-desktop" style="overflow: hidden;">
          <img *ngIf="imageUrls?.[2]" [src]="imageUrls[2] | secureUrl" alt="">
        </div>
        <div class="text-column right">
          <h2>{{about?.fields?.sectionTitleVision}}</h2>
          <span [innerHTML]="about?.fields?.sectionContentVisionHtml"></span>

          <h2>{{about?.fields?.sectionTitleMission}}</h2>
          <span [innerHTML]="about?.fields?.sectionContentMissionHtml"></span>
        </div>
      </div>
      <div class="bottom-images">
        <div class="picture-mobile" style="width: 90%;">
        </div>
        <img *ngIf="imageUrls?.[3]" [src]="imageUrls[3] | secureUrl" alt="" style="max-width: 90%;">
      </div> -->

      <div [innerHTML]="about?.fields?.sectionContentHtml"></div>
      <h2 class="subtitle">{{about?.fields?.sectionTitleVision}}</h2>
      <span [innerHTML]="about?.fields?.sectionContentVisionHtml"></span>

      <h2 class="subtitle">{{about?.fields?.sectionTitleMission}}</h2>
      <span [innerHTML]="about?.fields?.sectionContentMissionHtml"></span>
    </div>
  </div>
  <cds-scroll direction="down" [scrollContainer]="scrollContainer"></cds-scroll>
</div>
