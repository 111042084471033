import { LanguageService } from './../services/language.service';
import { Injectable } from '@angular/core';
import { CONFIG } from '../services/contentful';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  lang: any = localStorage.getItem('lang')
    ? localStorage.getItem('lang')
    : 'ro';
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken,
  });

  constructor(private languageService: LanguageService) {}

  getAllProjects(): Promise<unknown[]> {
    return this.cdaClient
      .getEntries(
        Object.assign({
          content_type: 'project',
          order: 'fields.category',
        })
      )
      .then((res) => res.items);
  }

  getAllProjectsShort(): Observable<unknown[]> {
    return this.languageService.currentLang$.pipe(
      switchMap((lang: any) =>
        this.cdaClient
          .getEntries(
            Object.assign({
              content_type: 'project',
              select:
                'sys.id,fields.shortName,fields.name,fields.mainPhoto,fields.category',
              locale: lang,
              order: '-fields.order',
            })
          )
          .then((res: any) => res.items)
      )
    );
  }

  getProjectById(projectId: string): Observable<unknown> {
    return this.languageService.currentLang$.pipe(
      switchMap((lang: any) =>
        this.cdaClient
          .getEntry(projectId, { locale: lang })
          .then((res: any) => res)
      )
    );
  }

  getAllProjectsShortByCategory(category: string): Promise<unknown[]> {
    return this.cdaClient
      .getEntries(
        Object.assign({
          content_type: 'project',
          select: 'fields.shortName,fields.mainPhoto',
          'fields.category[match]': category,
        })
      )
      .then((res) => res.items);
  }

  getProjectLocations() {
    return this.cdaClient
      .getEntries(
        Object.assign({
          content_type: 'projectsLocation',
          locale: this.lang,
          order: 'sys.createdAt',
        })
      )
      .then((res) => res.items);
  }

  richTextToHtml(richText: any): string {
    return documentToHtmlString(richText);
  }
}
