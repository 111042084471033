<img class="logo white animate__animated animate__fadeIn animate__slower"
  src="../../assets/Logo-CDS-editabil-300-dpi_White-transparent.png" alt="" width="128px" height="128px"
  (click)="navigateTo('/home')" *ngIf="isHomePage && introPlayed" [ngClass]="{'first': introPlayed && isHomePage}">
<img class="logo black" src="../../assets/Logo-CDS-editabil-300-dpi_Black-transparent.png" alt="" width="128px"
  height="128px" (click)="navigateTo('/home')" *ngIf="!isHomePage">
<img class="logo mobile" src="../../assets/Logo-CDS-editabil-300-dpi_Black-transparent.png" alt=""
  *ngIf="!isHomePage && !isProjects && introPlayed" (click)="toggleSidenav()">
<img class="logo mobile projects animate__animated animate__fadeIn"
  src="../../assets/Logo-CDS-editabil-300-dpi_White-transparent.png" alt="" *ngIf="isProjects && introPlayed"
  (click)="toggleSidenav()" [ngClass]="{'animate__fadeIn': !animationTrigger, 'animate__fadeOut': animationTrigger}">
<img class="logo mobile home black animate__animated animate__fadeIn animate__slower"
  src="../../assets/Logo-CDS-editabil-300-dpi_Black-transparent.png" alt=""
  *ngIf="isSidenavOpen && isProjects && introPlayed" (click)="toggleSidenav()"
  [ngClass]="{'animate__fadeIn': animationTrigger, 'animate__fadeOut': !animationTrigger}">
<img class="logo mobile home animate__animated animate__fadeIn"
  src="../../assets/Logo-CDS-editabil-300-dpi_White-transparent.png" alt="" *ngIf="isHomePage && introPlayed"
  (click)="navigateTo('/home')">
<img class="flag animate__animated animate__fadeIn animate__slower" [src]="flag" #flagImage alt="" width="32px"
  height="32px" (click)="changeLang()" [ngClass]="{'first': introPlayed && isHomePage}" *ngIf="introPlayed">

<!-- sidenav-desktop -->
<div class="pages-list-container">
  <div style="padding-bottom: 24px; height: 64px;">
    <div *ngIf="isProjects">
      <div>
        <button class="page-list animate__animated animate__fadeIn"
          [ngClass]="{'white': isHomePage, 'black': !isHomePage}" (click)="playClick(); navigateTo('/projects/type')">{{
          'sidenav.projects-type' | translate }}</button>
      </div>
      <div>
        <button class="page-list animate__animated animate__fadeIn"
          [ngClass]="{'white': isHomePage, 'black': !isHomePage}"
          (click)="playClick(); navigateTo('/projects/place')">{{
          'sidenav.projects-place' | translate }}</button>
      </div>
    </div>
  </div>

  <div>
    <div class="slide first" *ngIf="introPlayed && isHomePage && !animationPlayed"></div>
    <button class="page-list up-case animate__animated animate__fadeIn" *ngIf="introPlayed"
      [ngClass]="{'white': isHomePage, 'black': !isHomePage, 'first': introPlayed && isHomePage}"
      (click)="playClick(); navigateTo('/projects/type')">{{ 'sidenav.projects' | translate }}</button>
  </div>
  <div>
    <div class="slide second" *ngIf="introPlayed && isHomePage && !animationPlayed"></div>
    <button class="page-list up-case animate__animated animate__fadeIn" *ngIf="introPlayed"
      [ngClass]="{'white': isHomePage, 'black': !isHomePage, 'second': introPlayed && isHomePage}"
      (click)="playClick(); isCompanyClicked = !isCompanyClicked">{{ 'sidenav.company' | translate }}</button>
  </div>
  <div
    *ngIf="router.url === '/about' || router.url === '/parteners' || router.url === '/beneficiaries' || isCompanyClicked">
    <div>
      <div class="slide" *ngIf="introPlayed && isHomePage && !animationPlayed"></div>
      <button class="page-list secondary up-case animate__animated animate__fadeIn" *ngIf="introPlayed"
        [ngClass]="{'white': isHomePage, 'black': !isHomePage, 'first': introPlayed && isHomePage}"
        (click)="playClick(); navigateTo('/about')">{{ 'sidenav.about' | translate }}</button>
    </div>
    <div>
      <div class="slide" *ngIf="introPlayed && isHomePage && !animationPlayed"></div>
      <button class="page-list secondary up-case animate__animated animate__fadeIn" *ngIf="introPlayed"
        [ngClass]="{'white': isHomePage, 'black': !isHomePage, 'second': introPlayed && isHomePage}"
        (click)="playClick(); navigateTo('/parteners')">{{ 'sidenav.partners' | translate }}</button>
    </div>
    <div>
      <div class="slide" *ngIf="introPlayed && isHomePage && !animationPlayed"></div>
      <button class="page-list secondary up-case animate__animated animate__fadeIn" *ngIf="introPlayed"
        [ngClass]="{'white': isHomePage, 'black': !isHomePage, 'third': introPlayed && isHomePage}"
        (click)="playClick(); navigateTo('/beneficiaries')">{{ 'sidenav.beneficiaries' | translate }}</button>
    </div>
  </div>
  <!-- <div>
    <div class="slide third" *ngIf="introPlayed && isHomePage && !animationPlayed"></div>
    <button button class="page-list up-case animate__animated animate__fadeIn" *ngIf="introPlayed"
      [ngClass]="{'white': isHomePage, 'black': !isHomePage, 'third': introPlayed && isHomePage}"
      (click)="playClick(); navigateTo('/people')">{{ 'sidenav.people' | translate }}</button>
  </div> -->
  <div>
    <div class="slide third" *ngIf="introPlayed && isHomePage && !animationPlayed"></div>
    <button class="page-list up-case animate__animated animate__fadeIn" *ngIf="introPlayed"
      [ngClass]="{'white': isHomePage, 'black': !isHomePage, 'third': introPlayed && isHomePage}"
      (click)="playClick(); navigateTo('/careers')">{{ 'sidenav.careers' | translate }}</button>
  </div>
  <div>
    <div class="slide fourth" *ngIf="introPlayed && isHomePage && !animationPlayed"></div>
    <button class="page-list up-case animate__animated animate__fadeIn" *ngIf="introPlayed"
      [ngClass]="{'white': isHomePage, 'black': !isHomePage, 'fourth': introPlayed && isHomePage}"
      (click)="playClick(); navigateTo('/news')">{{ 'sidenav.news' | translate }}</button>
  </div>
  <div>
    <div class="slide fifth" *ngIf="introPlayed && isHomePage && !animationPlayed"></div>
    <button class="page-list up-case animate__animated animate__fadeIn" *ngIf="introPlayed"
      [ngClass]="{'white': isHomePage, 'black': !isHomePage, 'fifth': introPlayed && isHomePage}"
      (click)="playClick(); navigateTo('/contact')">{{ 'sidenav.contact' | translate }}</button>
  </div>
</div>


<!-- sidenav-mobile -->
<div class="pages-list-container-mobile animate__animated" *ngIf="isSidenavOpen"
  [ngClass]="{'fadeInLeftAnimation': animationTrigger, 'fadeOutLeftAnimation': !animationTrigger}">
  <div style="height: 100%; display: flex; flex-direction: column; padding-top: 100px;">
    <button class="page-list up-case page-list-mobile-sidenav" (click)="playClick(); navigateTo('/projects/type')">{{
      'sidenav.projects' | translate }}</button>
    <button class="page-list up-case page-list-mobile-sidenav"
      (click)="isCompanyClicked = !isCompanyClicked">{{'sidenav.company' | translate }}</button>
    <button
      *ngIf="router.url === '/about' || router.url === '/parteners' || router.url === '/beneficiaries' || isCompanyClicked"
      class="page-list secondary up-case page-list-mobile-sidenav" (click)="playClick(); navigateTo('/about')">{{
      'sidenav.about' |
      translate }}</button>
    <button
      *ngIf="router.url === '/about' || router.url === '/parteners' || router.url === '/beneficiaries' || isCompanyClicked"
      class="page-list secondary up-case page-list-mobile-sidenav" (click)="playClick(); navigateTo('/parteners')">{{
      'sidenav.partners' | translate }}</button>
    <button
      *ngIf="router.url === '/about' || router.url === '/parteners' || router.url === '/beneficiaries' || isCompanyClicked"
      class="page-list secondary up-case page-list-mobile-sidenav"
      (click)="playClick(); navigateTo('/beneficiaries')">{{
      'sidenav.beneficiaries' | translate }}</button>
    <!-- <button class="page-list page-list-mobile-sidenav" (click)="playClick(); navigateTo('/people')">{{ 'sidenav.people'
      | translate }}</button> -->
    <button class="page-list up-case page-list-mobile-sidenav" (click)="playClick(); navigateTo('/careers')">{{
      'sidenav.careers' | translate }}</button>
    <button class="page-list up-case page-list-mobile-sidenav" (click)="playClick(); navigateTo('/news')">{{
      'sidenav.news' |
      translate }}</button>
    <button class="page-list up-case page-list-mobile-sidenav" (click)="playClick(); navigateTo('/contact')">{{
      'sidenav.contact' | translate }}</button>
  </div>
  <div style="height: 100px; display: flex; align-items: center; justify-content: center;">
    <img class="flag-mobile animate__animated animate__fadeIn" [src]="flag" #flagImage alt="" width="32px" height="32px"
      (click)="changeLang()">
  </div>
</div>
