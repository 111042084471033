<button class="exit-button" (click)="onNoClick()">{{'projects.exit' | translate}}</button>

<div *ngIf="!showProjectDetails">

  <div class="secondary-page">
    <div class="animate__animated animate__fadeIn secondary-page-main-container">
      <div class="secondary-page-title-container">
        <h1 class="secondary-page-title animate__animated animate__fadeIn">
          {{data.fields.name}}
        </h1>
      </div>


      <div #scrollContainer class="projects-container secondary-page-content-container scrollable-container"
        *ngIf="!isMobile">
        <!-- <cds-scroll direction="up" [scrollContainer]="scrollContainer"></cds-scroll> -->

        <div class="category-container" *ngFor="let category of categories">
          <span class="category-title" [innerHTML]="category | addLineBreaks"></span>
          <div class="item-container" *ngFor="let project of groupedProjects[category]"
            (click)="getProjectById(project.id)">
            <div class="image-container">
              <img [src]="project.mainPhoto?.fields.file.url" alt="" class="image" />
            </div>
            <span class="item-title">{{ project.shortName }}</span>
          </div>
        </div>
        <!-- <cds-scroll direction="down" [scrollContainer]="scrollContainer"></cds-scroll> -->

      </div>
      <div class="projects-container secondary-page-content-container scrollable-container" *ngIf="isMobile">
      <div class="item-container" *ngFor="let project of allProjectsShort" (click)="getProjectById(project.id)">
        <div class="image-container">
          <img [src]="project.mainPhoto?.fields.file.url" alt="" class="image" />
        </div>
        <span class="item-title">{{ project.name }}</span>
      </div>
    </div>
    </div>
  </div>
</div>

<ng-container *ngIf="showProjectDetails" style="height: 100%;">
  <cds-project-details style="height: 100%;" [embeddedData]="selectedProjectData" [isDetails]="true" (close)="closeProjectDetails()"></cds-project-details>
</ng-container>
