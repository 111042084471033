import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from '../shared/shared.module';
import { CareersService } from './careers.service';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'cds-send-email',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './send-email.component.html',
  styleUrl: './send-email.component.scss',
})
export class SendEmailComponent {
  email: string = '';
  message: string = '';
  attachment: File | null = null;
  fileError: string | null = null;
  isSubmitting: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private careersService: CareersService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    const allowedFormats = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const maxSize = 25 * 1024 * 1024; // 25MB

    if (!allowedFormats.includes(file.type)) {
      this.fileError =
        'Invalid file format. Please upload a PDF, DOC, or DOCX file.';
      this.attachment = null;
    } else if (file.size > maxSize) {
      this.fileError = 'File size exceeds the 25MB limit.';
      this.attachment = null;
    } else {
      this.fileError = null;
      this.attachment = file;
    }
  }

  removeAttachment(fileInput: any) {
    this.attachment = null;
    this.fileError = null;
    fileInput.value = ''; // Reset the file input
  }

  onSubmit() {
    if (!this.email || !this.message) {
      return;
    }

    this.isSubmitting = true;

    const formData = new FormData();
    formData.append('email', this.email);
    formData.append('message', this.message);
    formData.append('title', this.data.title);
    if (this.attachment) {
      formData.append('attachment', this.attachment);
    }

    this.careersService.sendEmail(formData).subscribe(
      (res) => {
        console.log('Email sent successfully', res);
        this.isSubmitting = false;
      },
      (error) => {
        console.error('Error sending email', error);
        this.isSubmitting = false;
      }
    );
  }
}
