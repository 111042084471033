import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private langSubject: BehaviorSubject<string> = new BehaviorSubject<string>('ro'); // Assuming 'ro' is the default language
  public currentLang$: Observable<string> = this.langSubject.asObservable();

  constructor() {}

  // Call this method to change the language and notify subscribers
  changeLanguage(lang: string): void {
    this.langSubject.next(lang);
  }
}
