<div class="secondary-page">
  <div class="animate__animated animate__fadeIn secondary-page-main-container">
    <div class="secondary-page-title-container">
      <h1 class="secondary-page-title animate__animated animate__fadeInRight">{{ 'partners.title' | translate }}</h1>
    </div>

    <cds-scroll direction="up" [scrollContainer]="scrollContainer"></cds-scroll>

    <div class="parteners-container secondary-page-content-container scrollable-container" #scrollContainer>
      <div class="image-container">
        <img class="image wide" src="../../assets/partners/concelex.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/partners/erbasu.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/partners/canpower.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wide" src="../../assets/partners/terragaz.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/partners/mc.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wide" src="../../assets/partners/pallex.jpg" alt="">
      </div>
      <div class="image-container">
        <img class="image wide" src="../../assets/partners/bogart.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wide" src="../../assets/partners/daro.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/partners/utcb.png" alt="">
      </div>
    </div>
  </div>
  <cds-scroll direction="down" [scrollContainer]="scrollContainer"></cds-scroll>
</div>
