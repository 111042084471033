import { NewsService } from './news.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'cds-news',
  standalone: true,
  imports: [SharedModule, MatExpansionModule, MatIconModule],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
})
export class NewsComponent implements OnInit {
  newsItems: any;
  panelOpenState = false;

  isMobile = window.innerWidth < 768 ? true : false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event?.target.innerWidth > 768) {
      this.isMobile = false;
    }
    if (event?.target.innerWidth < 768) {
      this.isMobile = true;
    }
  }

  constructor(
    private newsService: NewsService,
    private meta: Meta,
    private title: Title
  ) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'CDS se concentrează pe integrarea standardelor de construcție avansate, BIM și inteligența artificială, reflectând angajamentul nostru față de inovație.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle('Noutăți | Concrete & Design Solutions');
  }

  ngOnInit() {
    this.addMetaTags();
    this.getNews();
  }

  getNews() {
    this.newsService.loadNews().subscribe((res: any) => {
      this.newsItems = res;
    });
  }

  getRichText(richText: any) {
    if (!richText) {
      return ''; // Return an empty string if the rich text content is not yet available
    }
    return this.newsService.richTextToHtml(richText);
  }
}
