<div class="secondary-page">
  <div class="animate__animated animate__fadeIn secondary-page-main-container">
    <div class="secondary-page-title-container">
      <h1 class="secondary-page-title animate__animated animate__fadeInRight">{{ 'beneficiaries.title' | translate }}</h1>
    </div>

   <cds-scroll direction="up" [scrollContainer]="scrollContainer"></cds-scroll>

    <div class="beneficiaries-container secondary-page-content-container scrollable-container" #scrollContainer>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/pc.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/ps2.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/ps3.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/beneficiaries/ps4.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/beneficiaries/ps5.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/ps6.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/beneficiaries/cji.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/beneficiaries/pmi.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/cni.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/tsr.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wide" src="../../assets/beneficiaries/mrma.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/cjmhd.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/pmdg.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/tsr2.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/beneficiaries/upb.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/beneficiaries/umf.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/hbi.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wider" src="../../assets/beneficiaries/pisc.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wide" src="../../assets/beneficiaries/blnm.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/beneficiaries/pct.png" alt="">
      </div>
      <div class="image-container">
        <img class="image wide" src="../../assets/beneficiaries/pct2.png" alt="">
      </div>
      <div class="image-container">
        <img class="image thin" src="../../assets/beneficiaries/cjct.png" alt="">
      </div>
    </div>
  </div>

  <cds-scroll direction="down" [scrollContainer]="scrollContainer"></cds-scroll>
</div>
