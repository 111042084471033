<div *ngIf="!introPlayed && isMobile">
  <video autoplay [playsInline]="true" (ended)="introEnded($event)" (play)="playing()" [muted]="true" id="myVideo"
    style="z-index: 0;" class="intro-video animate__animated" [ngClass]="{'animate__fadeOut': isIconAppeared}">
    <source src="../../assets/Intro SITE_800x400_1.mp4" type="video/mp4">
  </video>
</div>

<div *ngIf="isMobile">
  <video autoplay [playsInline]="true" [muted]="true" loop id="myVideo" class="animate__animated animate__fadeIn"
    [ngClass]="{'bg-video': !introPlayed && introStarted}" style="z-index: 0;">
    <source src="../../assets/Showreel CDS_background.mp4" type="video/mp4">
  </video>
</div>

<div class="filter-button animate__animated animate__fadeIn animate__slower" (click)="toggleFilters()" *ngIf="isMobile && introPlayed">
  <img src="../../assets/projects/filters-w.png" width="24" *ngIf="!animationTrigger">
  <img src="../../assets/projects/filters-b.png" width="24" *ngIf="animationTrigger">
</div>
<div class="filters-container animate__animated"
  [ngClass]="{'fadeInRightAnimation': animationTrigger, 'fadeOutrightAnimation': !animationTrigger}"
  *ngIf="isFiltersOpen">
  <div class="category-title" style="padding-left: 1em;">{{'sidenav.projects-type' | translate}}</div>
  <span class="category-filter" (click)="changeLangFilters('health')">•{{ 'projects.category.health' | translate }}</span>
  <span class="category-filter" (click)="changeLangFilters('infrastructure')">•{{ 'projects.category.infrastructure' | translate}}</span>
  <span class="category-filter" (click)="changeLangFilters('sports')">•{{ 'projects.category.sports' | translate }}</span>
  <div class="" style="display: flex; padding-left: 2em;">
    <span>•</span>
    <span class="category-filter" style="padding-left: 0px;" (click)="changeLangFilters('administration')" [innerHTML]="'projects.category.administration' | translate | addLineBreaks"></span>
  </div>
  <span class="category-filter" (click)="changeLangFilters('education')">•{{ 'projects.category.education' | translate }}</span>
  <span class="category-filter" (click)="changeLangFilters('hospitality')">•{{ 'projects.category.hospitality' | translate }}</span>
  <span class="category-filter" (click)="changeLangFilters('residential')">•{{ 'projects.category.residential' | translate }}</span>
  <span class="category-filter" (click)="changeLangFilters('commercial')">•{{ 'projects.category.commercial' | translate }}</span>
  <div class="" style="display: flex; padding-left: 2em;">
    <span>•</span>
    <span class="category-filter" style="padding-left: 0px;" (click)="changeLangFilters('administration')" [innerHTML]="'projects.category.urbanReconstruction' | translate | addLineBreaks"></span>
  </div>
  <div class="category-title" style="padding-left: 1em;" [routerLink]="['/projects/place']" routerLinkActive="router-link-active" >{{'sidenav.projects-place' | translate}}</div>
</div>

<div class="secondary-page" *ngIf="introPlayed">
  <div class="animate__animated animate__fadeIn secondary-page-main-container">
    <div class="secondary-page-title-container">
      <h1 class="secondary-page-title animate__animated animate__fadeInRight">
        {{ 'projects.title' | translate }}
      </h1>
    </div>


    <div #scrollContainer class="projects-container secondary-page-content-container scrollable-container"
      *ngIf="!isMobile">
      <cds-scroll direction="up" [scrollContainer]="scrollContainer"></cds-scroll>

      <div class="category-container" *ngFor="let category of categories">
        <span class="category-title" [innerHTML]="category | addLineBreaks"></span>
        <div class="item-container" *ngFor="let project of groupedProjects[category]"
          (click)="getProjectById(project.id)">
          <div class="image-container">
            <img [src]="project.mainPhoto?.fields.file.url" alt="" class="image" />
          </div>
          <span class="item-title">{{ project.shortName }}</span>
        </div>
      </div>
      <cds-scroll direction="down" [scrollContainer]="scrollContainer"></cds-scroll>

    </div>
    <div class="projects-container secondary-page-content-container scrollable-container" *ngIf="isMobile">
      <div class="item-container" *ngFor="let project of allProjectsShort" (click)="getProjectById(project.id)">
        <div class="image-container">
          <img [src]="project.mainPhoto?.fields.file.url" alt="" class="image" />
        </div>
        <span class="item-title">{{ project.name }}</span>
      </div>
    </div>
  </div>
</div>
