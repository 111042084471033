<button class="exit-button" (click)="onNoClick()" *ngIf="!isDetails">{{'projects.exit' | translate}}</button>

<div class="dialog-container">
  <div class="info-container">
    <div class="info-main">
      <div class="image-container">
        <img class="image" [src]="data.fields?.mainPhoto?.fields?.file?.url" />
      </div>
      <div class="info-details-container">
        <div class="name align-end">{{data.fields?.name}}</div>
        <div class="location align-end">{{data.fields?.location}}</div>
        <div class="year align-end">{{data.fields?.year}}</div>
      </div>
    </div>
    <div class="info-details-secondary-container align-end">
      <div class="align-end" *ngIf="data.fields?.client">
        <div class="info-subcategory">{{'projects.client' | translate}}</div>
        <div class="info-subcategory-details">{{data.fields?.client}}</div>
      </div>
      <div class="align-end" *ngIf="data.fields?.category">
        <div class="info-subcategory">{{'projects.typology' | translate}}</div>
        <div class="info-subcategory-details">{{data.fields?.category}}</div>
      </div>
      <div class="align-end" *ngIf="data.fields?.size">
        <div class="info-subcategory">{{'projects.size' | translate}}</div>
        <div class="info-subcategory-details">{{data.fields?.size}}</div>
      </div>
      <div class="align-end" *ngIf="data.fields?.status">
        <div class="info-subcategory">{{'projects.status' | translate}}</div>
        <div class="info-subcategory-details">{{data.fields?.status}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="photos.length > 0" class="photo-container">
    <div *ngFor="let photo of photos; let i = index" [class.active]="i === currentPhotoIndex" class="photo-wrapper">
      <img [src]="photo" alt="Full screen carousel photo">
    </div>
  </div>
  <div class="description-container" *ngIf="isHandset && data?.fields?.descriptionHtml"
    [innerHTML]="descriptionExpanded ? data?.fields?.descriptionHtml : ('projects.more' | translate)" (click)="toggleDescription()"></div>
  <div class="description-container" *ngIf="!isHandset" [innerHTML]="data?.fields?.descriptionHtml"></div>
</div>
