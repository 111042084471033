import { LanguageService } from './../services/language.service';
import { Injectable } from '@angular/core';
import { CONFIG } from '../services/contentful';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  lang: any = localStorage.getItem('lang')
    ? localStorage.getItem('lang')
    : 'ro';
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken,
  });

  constructor(private languageService: LanguageService) {}

  getAbout(): Observable<unknown> {
    return this.languageService.currentLang$.pipe(
      switchMap((lang: any) =>
        this.cdaClient
          .getEntries({
            content_type: 'about',
            locale: lang,
          })
          .then((res) => res)
      )
    );
  }

  richTextToHtml(richText: any): string {
    return documentToHtmlString(richText);
  }

  processEntryForImages(entry: any, includes: any): string[] {
    return entry.fields.images
      .map((image: any) => {
        const imageId = image.sys.id;
        const asset = includes.Asset.find(
          (asset: any) => asset.sys.id === imageId
        );
        if (
          asset &&
          asset.fields &&
          asset.fields.file &&
          asset.fields.file.url
        ) {
          return 'https:' + asset.fields.file.url;
        } else {
          return null;
        }
      })
      .filter((url: string | null) => url !== null);
  }
}
