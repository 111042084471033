<div class="secondary-page">
  <div class="animate__animated animate__fadeIn secondary-page-main-container">
    <div class="secondary-page-title-container">
      <h1 class="secondary-page-title animate__animated animate__fadeInRight">{{ 'contact.title' | translate }}</h1>
    </div>
    <div class="contact-map-container">
      <div class="contact-container secondary-page-content-container scrollable-container"
        style="position: relative; z-index: 1;">
        <div class="socials-container" *ngIf="screenWidth > 768;">
          <span class="item-title">{{ 'contact.follow' | translate }}</span>
          <div class="social-icons" style="display: flex;">
            <a href="https://www.facebook.com/cds.architecture/" target="_blank">
              <img class="icon" src="../../assets/facebook.png" alt="Facebook"
                style="width: 48px; margin:8px 8px 8px 0; cursor: pointer;">
            </a>
            <a href="https://www.instagram.com/concretedesignsolutions/" target="_blank">
              <img class="icon" src="../../assets/instagram.png" alt="Instagram"
                style="width: 48px; margin: 8px; cursor: pointer;">
            </a>
            <a href="https://www.linkedin.com/company/cdsro" target="_blank">
              <img class="icon" src="../../assets/linkedin.png" alt="LinkedIn"
                style="width: 48px; margin: 8px; cursor: pointer;">
            </a>
            <a href="https://twitter.com/cdsarchitecture" target="_blank">
              <img class="icon" src="../../assets/twitter.png" alt="Twitter"
                style="width: 48px; margin: 8px; cursor: pointer;">
            </a>
          </div>
        </div>
        <span class="item title" style="width: auto;">{{ 'contact.addressTitle' | translate }}</span>
        <div style="padding-bottom: 1.5vh;">
          <span class="item">{{ 'contact.addressLine1' | translate }}</span><br>
          <span class="item">{{ 'contact.addressLine2' | translate }}</span><br>
          <span class="item">{{ 'contact.addressLine3' | translate }}</span><br>
          <span class="item">{{ 'contact.addressLine4' | translate }}</span><br>
          <span class="item">{{ 'contact.phoneLabel' | translate }} {{ 'contact.phone' | translate }}</span>
        </div>
        <div style="padding-bottom: 1.5vh;">
          <span class="item">{{ 'contact.office' | translate }}</span><br>
          <span class="item">{{ 'contact.officeEmail' | translate }}</span>
        </div>
        <div style="padding-bottom: 1.5vh;">
          <span class="item">{{ 'contact.newBusiness' | translate }}</span><br>
          <span class="item">{{ 'contact.businessEmail' | translate }}</span>
        </div>
        <div style="padding-bottom: 1.5vh;">
          <span class="item">{{ 'contact.careers' | translate }}</span><br>
          <span class="item">{{ 'contact.careersEmail' | translate }}</span>
        </div>
        <div class="socials-container-mobile" *ngIf="screenWidth < 768;">
          <span class="socials-title-mobile">{{ 'contact.follow' | translate }}</span>
          <div class="social-icons-mobile">
            <a href="https://www.facebook.com/cds.architecture/" target="_blank">
              <img class="icon-mobile" src="../../assets/facebook.png" alt="Facebook">
            </a>
            <a href="https://www.instagram.com/concretedesignsolutions/" target="_blank">
              <img class="icon-mobile" src="../../assets/instagram.png" alt="Instagram">
            </a>
            <a href="https://www.linkedin.com/company/cdsro" target="_blank">
              <img class="icon-mobile" src="../../assets/linkedin.png" alt="LinkedIn">
            </a>
            <a href="https://twitter.com/cdsarchitecture" target="_blank">
              <img class="icon-mobile" src="../../assets/twitter.png" alt="Twitter">
            </a>
            <a href="https://maps.app.goo.gl/rdFtzh3RfHQLSfcTA" target="_blank">
              <img class="icon-mobile" src="../../assets/contact/pin.png" alt="Twitter">
            </a>
          </div>
        </div>
      </div>
      <div class="">
        <div id="map" style="height: 100%; width: 50vw;"></div>
      </div>
    </div>
  </div>
</div>
