import { HomeGuard } from './../../guards/home.guard';
import { IntroGuard } from './../../guards/intro.guard';
import { Route } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { ProjectsComponent } from './projects/projects.component';
import { PeopleComponent } from './people/people.component';
import { PartenersComponent } from './parteners/parteners.component';
import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
import { CareersComponent } from './careers/careers.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { ProjectsPlaceComponent } from './projects/projects-place.component';

export const appRoutes: Route[] = [
  { path: '', redirectTo: '/home', pathMatch: 'full', canActivate: [HomeGuard]  },
  { path: 'home', component: HomeComponent, canActivate: [HomeGuard] },
  { path: 'about', component: AboutComponent, canActivate: [IntroGuard] },
  { path: 'projects', redirectTo: 'projects/type'},
  { path: 'projects/type', component: ProjectsComponent },
  { path: 'projects/place', component: ProjectsPlaceComponent, canActivate: [IntroGuard] },
  { path: 'team', component: PeopleComponent, canActivate: [IntroGuard] },
  { path: 'parteners', component: PartenersComponent, canActivate: [IntroGuard] },
  { path: 'beneficiaries', component: BeneficiariesComponent, canActivate: [IntroGuard] },
  { path: 'careers', component: CareersComponent, canActivate: [IntroGuard] },
  { path: 'news', component: NewsComponent, canActivate: [IntroGuard] },
  { path: 'contact', component: ContactComponent, canActivate: [IntroGuard] },
];
