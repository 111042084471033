import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private isOpenSidenav = new BehaviorSubject<boolean>(false);
  private isOpenFilters = new BehaviorSubject<boolean>(false);

  constructor() { }

  toggleSidenav(): void {
    setTimeout(() => {
      this.isOpenSidenav.next(!this.isOpenSidenav.value);
    }, 200);
  }

  setStateSidenav(open: boolean): void {
    this.isOpenSidenav.next(open);
  }

  getStateSidenav(): BehaviorSubject<boolean> {
    return this.isOpenSidenav;
  }

  toggleFilters(): void {
    setTimeout(() => {
      this.isOpenFilters.next(!this.isOpenFilters.value);
    }, 200);
  }

  setStateFilers(open: boolean): void {
    this.isOpenFilters.next(open);
  }

  getStateFilters(): BehaviorSubject<boolean> {
    return this.isOpenFilters;
  }
}
