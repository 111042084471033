<div class="secondary-page">
  <div class="animate__animated animate__fadeIn secondary-page-main-container">
    <div class="secondary-page-title-container">
      <h1 class="secondary-page-title animate__animated animate__fadeInRight">{{ 'news.title' | translate }}</h1>
    </div>

    <cds-scroll direction="up" [scrollContainer]="scrollContainer"></cds-scroll>
    <div class="text-container secondary-page-content-container scrollable-container" #scrollContainer>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let item of newsItems" [hideToggle]="isMobile" (opened)="panelOpenState = true"
          (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="image-container">
                <img [src]="item.mainPhoto?.fields?.file?.url ? item.mainPhoto?.fields?.file?.url : '../../assets/cdsro_logo.jpeg'" alt="Main Photo" class="image">
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <div class="news-date">{{ item?.date }}
                <mat-icon aria-hidden="false" fontIcon="expand_more" [ngClass]="{'rotate-icon': panelOpenState}" *ngIf="isMobile"></mat-icon>
              </div>
              <span class="news-title">{{ item?.title }}</span>
              <span class="news-description" [innerHTML]="getRichText(item?.description)"></span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div [innerHTML]="getRichText(item?.fullText)"></div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <cds-scroll direction="down" [scrollContainer]="scrollContainer"></cds-scroll>

</div>
