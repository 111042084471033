import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TranslateService } from '@ngx-translate/core';
import { IntroService } from './services/intro.service';
import { Subscription, filter } from 'rxjs';
import { LanguageService } from './services/language.service';
import { GoogleAnalyticsService } from './google-analytics.service';
@Component({
  standalone: true,
  imports: [RouterModule, CommonModule, HomeComponent, SidenavComponent],
  selector: 'cds-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isHomePage: boolean = false;
  isProjects: boolean = false;
  introPlayed: boolean = false;

  lang = 'ro';

  introEndSubscription!: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private introService: IntroService,
    private languageService: LanguageService,
    private gaService: GoogleAnalyticsService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is the home page
        this.isHomePage = event.url === '/home' || event.url === '/';
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is the home page
        this.isProjects = event.url === '/projects' || event.url === '/';
      }
    });

    this.languageService.currentLang$.subscribe((lang: any) => {
      this.translate.use(lang);
    });

    this.trackPageViews();
  }

  ngOnInit(): void {
    this.playBackgroundAudio();
    this.getIntroEnded();
  }

  getIntroEnded() {
    this.introPlayed = this.introService.hasIntroPlayed;
    this.introEndSubscription = this.introService.introFinished.subscribe(
      (res) => {
        this.introPlayed = res;
      }
    );
  }

  setLang(event: any) {
    this.translate.use(event);
  }

  playBackgroundAudio() {
    const audio = new Audio();
    audio.src = '../assets/background music.mp3';
    audio.load();
    audio.volume = 0.02;
    audio.play();
  }

  trackPageViews() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.gaService.sendPageView(event.urlAfterRedirects);
    });
  }
}
