import { Injectable } from '@angular/core';
import { CONFIG } from '../services/contentful';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Observable, switchMap } from 'rxjs';
import { LanguageService } from '../services/language.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CareersService {
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken,
  });

  constructor(private languageService: LanguageService,
      private http: HttpClient
  ) {}

  getCareers(): Observable<any> {
    return this.languageService.currentLang$.pipe(
      switchMap((lang: any) =>
        this.cdaClient
          .getEntries({
            content_type: 'career',
            include: 1, // Ensure this is set to include linked entries/assets
            locale: lang,
            order: 'sys.updatedAt' as any,
          })
          .then((res: any) => {
            // Link each team member with their photo URL from the included assets
            const items = res.items.map((item: any) => {
              const imageId = item.fields.image?.sys.id;
              const imageUrl = res.includes.Asset.find(
                (asset: any) => asset.sys.id === imageId
              )?.fields.file.url;
              return {
                ...item.fields,
                imageUrl: imageUrl ? 'https:' + imageUrl : null, // Prepend 'https:' to make the URL absolute
              };
            });
            return items;
          })
      )
    );
  }

  richTextToHtml(richText: any): string {
    const options = {
      renderNode: {
        // Handling embedded assets (e.g., images)
        'embedded-asset-block': (node: any) => {
          const url = node.data.target.fields.file.url;
          const alt = node.data.target.fields.title;
          return `<img src="${url}" alt="${alt}"/>`;
        },
        // Add handlers for other types of embedded entries if needed
      },
      // Add any other options you need
    };

    return documentToHtmlString(richText, options);
  }

  sendEmail(formData: FormData) {
    return this.http.post('http://localhost:3000/api/send-email', formData);
  }
}
