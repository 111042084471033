import { Component, HostListener, OnInit } from '@angular/core';
import { CareersService } from './careers.service'; // Ensure you have a CareersService
import { SharedModule } from '../shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { SendEmailComponent } from './send-email.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'cds-careers',
  standalone: true,
  imports: [SharedModule, MatExpansionModule, MatIconModule],
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
})
export class CareersComponent implements OnInit {
  careersItems: any;
  panelOpenState = false;

  isMobile = window.innerWidth < 768 ? true : false;
  isDialogOpened = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = event?.target.innerWidth < 768 ? true : false;
  }

  constructor(
    private careersService: CareersService,
    public dialog: MatDialog,
    private meta: Meta,
    private title: Title
  ) {}

  addMetaTags() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Compania CDS, specializată soluții avansate în domeniul construcțiilor și designului, oferă oportunități de carieră într-o echipă multidisciplinară de peste 130 de specialiști.',
      },
      {
        name: 'keywords',
        content: 'CDS, proiectare, construcții, echipă multidisciplinară',
      },
    ]);
    this.title.setTitle('Carieră | Concrete & Design Solutions');
  }

  ngOnInit(): void {
    this.addMetaTags();
    this.getCareers();
  }

  getCareers() {
    this.careersService.getCareers().subscribe((res: any) => {
      this.careersItems = res;
    });
  }

  getRichText(richText: any) {
    if (!richText) {
      return ''; // Same function as in NewsComponent
    }
    return this.careersService.richTextToHtml(richText);
  }

  openDialog(item: any): void {
    if (this.isDialogOpened) {
    } else {
      this.isDialogOpened = true;
      const dialogRef = this.dialog.open(SendEmailComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: item,
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.isDialogOpened = false;
      });
    }
  }
}
